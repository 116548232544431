import React from 'react';

const FrontCover = props => {
  return (
    <div
      className="mini-single-journal-page mini-single-journal-page-front-cover"
      style={{
        backgroundColor: props.colorSettings.frontCover.color,
        textShadow: `5px 5px 0 ${props.colorSettings.frontCoverText.color}`
      }}
    >
      <span>MY</span>
      <span>MINI</span>
      <span>MCNA</span>
      <span>JOURNAL</span>
    </div>
  );
};

export default FrontCover;
