import { Outlet } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';
import { useSelector } from 'react-redux';

const PageLayout = () => {
  const mobile = useSelector(state => state.shopReducer.mobile);

  return (
    <>
      <Header />
      <div
        className="everything-except-header"
        style={{
          // height: mobile ? `calc(100vh - 105px)` : `calc(100vh - 60px)`
          height: `calc(100vh - 60px)`
        }}
      >
        <Outlet />
        <Footer />
      </div>
    </>
  );
};

export default PageLayout;
