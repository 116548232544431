import React, { useEffect, useState } from 'react';
import { Authenticator, withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { useSelector } from 'react-redux';

const AccountPage = () => {
  const auth = useSelector(state => state.shopReducer.auth);

  return (
    <div className="page account-page">
      <div style={{ paddingTop: !auth ? '100px' : '' }}>
        <Authenticator>
          {auth ? <div>AccountPage {auth.attributes.email}</div> : null}
        </Authenticator>
      </div>
    </div>
  );
};

export default AccountPage;
