import React from 'react';

const Day = props => {
  const renderRows = () => {
    let rows = [];
    for (const p in props.prayerTimes) {
      rows.push(
        <div className="fajr-row" key={`${p}-row`}>
          <div className="cell label">{p}</div>
          <div className="cell time">
            {props.prayerTimes[p].str + ` ` + props.prayerTimes[p].ampm}
          </div>
          <div className="cell input"></div>
          <div className="cell checkbox"></div>
        </div>
      );
    }
    return rows;
  };

  return (
    <div className="mini-single-journal-page mini-single-journal-page-day">
      <div className="mini-single-journal-page-day-content">
        <div
          className="mini-single-journal-page-day-header"
          style={{ backgroundColor: props.colorSettings.dayHeader.color }}
          onClick={() => props.setCurrentColorSetting('dayHeader')}
        >
          <div className="day">{props.day}</div>
          <div className="journal-daily-icons">
            <i className={`fa-solid fa-face-laugh`}></i>
            <i className={`fa-solid fa-face-smile`}></i>
            <i className={`fa-solid fa-face-meh`}></i>
            <i className={`fa-solid fa-face-sad-tear`}></i>
            <i className={`fa-solid fa-face-angry`}></i>
          </div>
        </div>

        <div className="rest-of-page">
          <div
            className="prayers"
            style={{ backgroundColor: props.colorSettings.prayers.color }}
            onClick={() => props.setCurrentColorSetting('prayers')}
          >
            {renderRows()}
          </div>
          <div className="quran">
            <div
              className="quran-boxes"
              onClick={() => props.setCurrentColorSetting('quranBoxes')}
            >
              <div className="quran-box">
                <div className="icon-left"></div>
                <i
                  className="fa-regular fa-clone fa-flip-horizontal fa-4x icon-right"
                  style={{ color: props.colorSettings.quranBoxes.color }}
                ></i>
              </div>
              <div className="quran-box">
                <div className="icon-left"></div>
                <i
                  className="fa-regular fa-clone fa-flip-horizontal fa-4x icon-right"
                  style={{ color: props.colorSettings.quranBoxes.color }}
                ></i>
              </div>
              <div className="quran-box">
                <div className="icon-left"></div>
                <i
                  className="fa-regular fa-clone fa-flip-horizontal fa-4x icon-right"
                  style={{ color: props.colorSettings.quranBoxes.color }}
                ></i>
              </div>
            </div>
            <div
              className="quran-label"
              style={{
                border: `2px solid ${props.colorSettings.quranLabel.color}`,
                color: `${props.colorSettings.quranLabel.color}`
              }}
              onClick={() => props.setCurrentColorSetting('quranLabel')}
            >
              reflections
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Day;
