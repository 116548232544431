import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { Country, State, City } from 'country-state-city';
import { Sketch } from '@uiw/react-color';

import listings from '../api/listings';

import MiniSingleJournal from '../listings/mini-single-journal/MiniSingleJournal';
import MiniMCNAJournal from '../listings/mini-mcna-journal/MiniMCNAJournal';

import { useDispatch, useSelector } from 'react-redux';
import { fetchPrayerTimes, undoRevertToCity } from '../store/shopSlice';

const ListingPage = props => {
  const dispatch = useDispatch();

  const mobile = useSelector(state => state.shopReducer.mobile);
  const revertToCity = useSelector(state => state.shopReducer.revertToCity);

  const { id } = useParams();
  const [listing, setListing] = useState(null);

  const [checkingOut, setCheckingOut] = useState(false);

  const [prayerSettings, setPrayerSettings] = useState({
    localeType: 'city',
    country: { label: 'United States', value: 'US' },
    state: { label: 'New York', value: 'NY' },
    city: '',
    school: 1,
    fajr: '18°',
    isha: '18°'
  });

  const [currentColorSetting, setCurrentColorSetting] = useState('frontCover');
  const [colorSettings, setColorSettings] = useState({
    frontCover: { label: 'Front Cover', color: '#f6d8ff' },
    frontCoverText: { label: 'Front Cover Text', color: '#5ce1e6' },
    backCover: { label: 'Back Cover', color: '#5ce1e6' },
    dayHeader: { label: 'Day Header', color: '#5ce1e6' },
    prayers: { label: 'Prayers', color: '#f6d8ff' },
    quranBoxes: { label: 'Goal Boxes', color: '#5ce1e6' },
    quranLabel: { label: 'Footer', color: '#e399fa' }
  });
  const [selectedThemeIndex, setSelectedThemeIndex] = useState(0);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    let l = listings.find(l => l._id === id);
    console.log(l);
    if (l) setListing(l);
  }, [id]);

  useEffect(() => {
    console.log(revertToCity);
    if (revertToCity) {
      alert('You have not given us permission to access your location.');
      updatePrayerSetting('localeType', 'city');
      dispatch(undoRevertToCity());
    }
  }, [dispatch, revertToCity]);

  useEffect(() => {
    console.log(listing);
    if (listing)
      if (listing.features.includes('prayerTimes')) {
        const allCountries = Country.getAllCountries().map(c => ({
          value: c.isoCode,
          label: c.name
        }));
        setCountries(allCountries);
      }
  }, [listing]);

  useEffect(() => {
    const allStates = State.getStatesOfCountry(prayerSettings.country.value).map(s => ({
      value: s.isoCode,
      label: s.name
    }));
    setStates(allStates);
    updatePrayerSetting('state', allStates[0]);
  }, [prayerSettings.country]);

  useEffect(() => {
    const allCities = City.getCitiesOfState(
      prayerSettings.country.value,
      prayerSettings.state.value
    ).map(c => c.name);
    console.log(allCities);
    updatePrayerSetting('city', allCities[0]);
    setCities(allCities);
  }, [prayerSettings.state]);

  useEffect(() => {
    console.log('prayer settings updated');
    dispatch(
      fetchPrayerTimes({
        year: 2023,
        month: 6,
        schoolCode: prayerSettings.school,
        methodCode: 3,
        localeType: prayerSettings.localeType, // 'location' // 'city'
        country: prayerSettings.country.label,
        state: prayerSettings.state.label,
        city: prayerSettings.city
      })
    );
  }, [dispatch, prayerSettings]);

  const updatePrayerSetting = (type, setting) => {
    setPrayerSettings({ ...prayerSettings, [type]: setting });
  };

  const updateColors = color => {
    setColorSettings({
      ...colorSettings,
      [currentColorSetting]: { ...colorSettings[currentColorSetting], color }
    });
  };

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: listing.originalPrice.toString()
          }
        }
      ]
    });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(details => {
      const name = details.payer.name.given_name;
      // alert(`Transaction completed by ${name}`);
    });
  };

  const renderThemeOptions = () => {
    const themeOptions = listing.themes.map((t, i) => {
      return (
        <div
          style={{
            backgroundColor: t.themeColor,
            border: selectedThemeIndex === i ? '2px solid black' : '2px solid white'
          }}
          className="color-option"
          onClick={() => {
            setSelectedThemeIndex(i);
            setColorSettings(listing.themes[i]);
          }}
          key={`theme-selector-options-${i}`}
        ></div>
      );
    });

    return themeOptions;
  };

  const renderCurrentColors = () => {
    let uniqueColors = [];
    for (let key in colorSettings) {
      uniqueColors.push(colorSettings[key].color);
    }
    uniqueColors = uniqueColors.filter((val, idx, arr) => {
      return arr.indexOf(val) === idx;
    });

    const currentColors = uniqueColors.map((c, i) => {
      return (
        <div
          style={{
            backgroundColor: c
          }}
          className="color-option"
          onClick={() => {
            updateColors(c);
          }}
          key={`current-colors-${i}`}
        ></div>
      );
    });

    return currentColors;
  };

  return (
    <div className="page listing-page">
      {listing ? (
        <div className="listing-page-content">
          <div className="listing-page-title-section">
            <div className="title">{listing.title}</div>
            <div className="subtitle">{listing.subtitle}</div>
            <div className="price-and-checkout">
              <div className="price">${listing.originalPrice}</div>
              {!checkingOut ? (
                <div className="checkout-button" onClick={() => setCheckingOut(true)}>
                  Checkout
                </div>
              ) : null}
            </div>
          </div>

          <div className="listing-page-display">
            {listing._id === 'mini-muslim-book-3-pack' ? (
              <MiniSingleJournal
                setCurrentColorSetting={setCurrentColorSetting}
                colorSettings={colorSettings}
              />
            ) : listing._id == 'mini-mcna-journal' ? (
              <MiniMCNAJournal
                setCurrentColorSetting={setCurrentColorSetting}
                colorSettings={colorSettings}
              />
            ) : null}
          </div>

          <div className="listing-page-details-container">
            <div className="listing-page-details">
              {!checkingOut ? (
                <>
                  <div className="color-selectors">
                    {listing.features.includes('themes') ? (
                      <>
                        <div className="color-selector">
                          <div className="color-selector-title">Theme</div>
                          <div className="color-selector-color-options">
                            {renderThemeOptions()}
                          </div>
                        </div>
                        {listing.features.includes('customColors') ? (
                          <div className="color-selector">
                            <div className="color-selector-title">Current colors</div>
                            <div className="color-selector-color-options">
                              {renderCurrentColors()}
                            </div>
                          </div>
                        ) : null}
                      </>
                    ) : null}
                  </div>

                  {listing.features.includes('customColors') ? (
                    <>
                      <div className="color-wheel-section">
                        <div className="color-wheel-title">
                          Change color for: {colorSettings[currentColorSetting].label}
                        </div>
                        <div className="color-wheel-container">
                          {/* {renderColorOptions()} */}
                          <Sketch
                            color={colorSettings[currentColorSetting].color}
                            onChange={color => {
                              updateColors(color.hex);
                            }}
                            // className="color-wheel"
                            // style={{ maxWidth: '100%', width: '300px', margin: 'auto' }}
                            style={{
                              margin: 'auto',
                              boxShadow: 'none',
                              border: `1px solid lightgrey`
                            }}
                            // onMouseLeave={() => hideColorWheel(i)}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}

                  <div className="option-selector-container">
                    {listing.features.includes('prayerTimes') ? (
                      <>
                        <div className="option-selector-label">Get Locale By:</div>
                        <div className="option-selector-selections">
                          <div
                            style={{
                              backgroundColor:
                                prayerSettings.localeType === 'location'
                                  ? 'black'
                                  : 'white',
                              color:
                                prayerSettings.localeType === 'location'
                                  ? 'white'
                                  : 'black'
                            }}
                            className="option-selection"
                            onClick={() => updatePrayerSetting('localeType', 'location')}
                          >
                            Location
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                prayerSettings.localeType === 'city' ? 'black' : 'white',
                              color:
                                prayerSettings.localeType === 'city' ? 'white' : 'black'
                            }}
                            className="option-selection"
                            onClick={() => updatePrayerSetting('localeType', 'city')}
                          >
                            City
                          </div>
                        </div>

                        {prayerSettings.localeType === 'city' ? (
                          <div className="dropdowns">
                            <Dropdown
                              options={countries}
                              onChange={c => updatePrayerSetting('country', c)}
                              value={prayerSettings.country}
                              placeholder="Select country"
                              className="dropdown"
                            />
                            <Dropdown
                              options={states}
                              onChange={s => updatePrayerSetting('state', s.value)}
                              value={prayerSettings.state}
                              placeholder="Select state"
                              className="dropdown"
                            />
                            <Dropdown
                              options={cities}
                              onChange={c => updatePrayerSetting('city', c.value)}
                              value={prayerSettings.city}
                              placeholder="Select city"
                              className="dropdown"
                            />
                          </div>
                        ) : null}
                      </>
                    ) : null}
                  </div>

                  {listing.features.includes('prayerTimesInDepth') ? (
                    <div className="option-selector-container">
                      <div className="option-selector-label">
                        Select a school (madhhab):
                      </div>
                      <div className="option-selector-selections">
                        <div
                          style={{
                            backgroundColor:
                              prayerSettings.school === 1 ? 'black' : 'white',
                            color: prayerSettings.school === 1 ? 'white' : 'black'
                          }}
                          className="option-selection"
                          onClick={() => updatePrayerSetting('school', 1)}
                        >
                          Hanafi
                        </div>
                        <div
                          style={{
                            backgroundColor:
                              prayerSettings.school == 0 ? 'black' : 'white',
                            color: prayerSettings.school === 0 ? 'white' : 'black'
                          }}
                          className="option-selection"
                          onClick={() => updatePrayerSetting('school', 0)}
                        >
                          Maliki, Shafi'i, Hanbali
                        </div>
                      </div>
                    </div>
                  ) : null}
                </>
              ) : (
                <div className="payment-container">
                  <PayPalScriptProvider
                    options={{
                      clientId: `test`
                    }}
                  >
                    <PayPalButtons
                      className=""
                      createOrder={createOrder}
                      onApprove={onApprove}
                    />
                  </PayPalScriptProvider>
                </div>
              )}
            </div>
            <div className="price-and-checkout">
              <div className="price">${listing.originalPrice}</div>
              {!checkingOut ? (
                <div className="checkout-button" onClick={() => setCheckingOut(true)}>
                  Checkout
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ListingPage;
