import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import $ from 'jquery';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import Day from './Day';
import BlankPage from './BlankPage';
import Cover from './Cover';
import Opening from './Opening';
import FrontCover from './FrontCover';
import BackCover from './BackCover';

const MiniSingleJournal = props => {
  const prayerTimes = useSelector(state => state.shopReducer.prayerTimes);

  const [downloading, setDownloading] = useState(false);
  const [displayDoc, setDisplayDoc] = useState(false);

  useEffect(() => {
    if (downloading) {
      const HTMLtoPDF = async () => {
        var doc = new jsPDF('p', 'in', [11, 8.5]);

        let pageGroups = $(`.mini-single-journal-page-group`).get();

        for (let i = 0; i < pageGroups.length; i += 2) {
          let page1 = await html2canvas(pageGroups[i], { scale: `8` });
          let dURL = page1.toDataURL('image/jpeg', 1.0);
          doc.addImage(dURL, 'JPEG', 0.5, 0.2, 7.5, 10.5);
          doc.addPage([8.5, 11]);

          let page2 = await html2canvas(pageGroups[i + 1], { scale: `8` });
          let dURL2 = page2.toDataURL('image/jpeg', 1.0);
          doc.addImage(dURL2, 'JPEG', 0.5, 0.2, 7.5, 10.5);

          if (i + 2 < pageGroups.length) doc.addPage([8.5, 11]);
        }

        doc.save('sample.pdf');
      };

      // // await HTMLtoPDF();
      HTMLtoPDF()
        .then(x => {
          console.log('done');
          setDownloading(false);
        })
        .catch(e => {
          console.log(e);
        });
    }
  }, [downloading]);

  async function onDownload() {
    console.log('hello');
    setDownloading(true);
  }

  const renderDoc = () => {
    let pages = [];

    pages.push(
      <Opening
        setCurrentColorSetting={props.setCurrentColorSetting}
        colorSettings={props.colorSettings}
      />
    );
    pages.push(<BlankPage />);

    prayerTimes.forEach((p, i) =>
      pages.push(
        <Day
          day={p.day}
          prayerTimes={p.timings}
          key={`journal-page-${i}`}
          setCurrentColorSetting={props.setCurrentColorSetting}
          colorSettings={props.colorSettings}
        />
      )
    );

    let numPages = pages.length;

    // add in filler pages
    let numNullPages = numPages % 8 > 0 ? 8 - (numPages % 8) : 0;
    for (let i = 0; i < numNullPages; i++) {
      pages.push(<BlankPage key={`${numPages + i}`} />);
      numPages++;
    }

    let pageGroups = [];

    // 4 pages on one side of sheet, 4 corresponding pages on back side
    for (let i = 0; i < numPages; i += 8) {
      pageGroups.push([
        [pages[numPages - i - 1], pages[i]],
        [pages[numPages - i - 3], pages[i + 2]]
      ]);
      pageGroups.push([
        [pages[i + 1], pages[numPages - i - 2]],
        [pages[i + 3], pages[numPages - i - 4]]
      ]);
    }

    console.log(pageGroups);

    let rendered = [];

    for (let i = 0; i < pageGroups.length; i++) {
      rendered.push(
        <div
          className="page-group mini-single-journal-page-group"
          key={`mini-single-journal-page-group-${i}`}
        >
          <div className="page-pair">
            {pageGroups[i][0][0]}
            {pageGroups[i][0][1]}
          </div>
          <div className="page-pair">
            {pageGroups[i][1][0]}
            {pageGroups[i][1][1]}
          </div>
        </div>
      );
    }

    return (
      <>
        <Cover
          displayDoc={displayDoc}
          setCurrentColorSetting={props.setCurrentColorSetting}
          colorSettings={props.colorSettings}
        />
        <div className="pages" onClick={onDownload}>
          {rendered}
        </div>
      </>
    );
  };

  const renderImages = () => {
    let images = [];

    images.push(
      <FrontCover
        key={`display-front-cover`}
        setCurrentColorSetting={props.setCurrentColorSetting}
        colorSettings={props.colorSettings}
      />
    );
    images.push(
      <BackCover
        key={`display-back-cover`}
        setCurrentColorSetting={props.setCurrentColorSetting}
        colorSettings={props.colorSettings}
      />
    );
    images.push(
      <Opening
        key={`display-opening-page`}
        setCurrentColorSetting={props.setCurrentColorSetting}
        colorSettings={props.colorSettings}
      />
    );
    images.push(
      <Day
        day={prayerTimes[0].day}
        prayerTimes={prayerTimes[0].timings}
        key={`display-day-page`}
        setCurrentColorSetting={props.setCurrentColorSetting}
        colorSettings={props.colorSettings}
      />
    );

    return (
      <>
        <div className="images">{images}</div>
      </>
    );
  };

  return (
    <>
      {prayerTimes.length > 0 ? (
        <div className="mini-single-journal">
          {/* DELETE ADMIN-BUTTON BEFORE PROD PUSH */}
          <div className="admin-button" onClick={() => setDisplayDoc(!displayDoc)}></div>
          {displayDoc ? renderDoc() : renderImages()}
        </div>
      ) : null}
    </>
  );
};

export default MiniSingleJournal;
