import React from 'react';
import { Link } from 'react-router-dom';

import listings from '../api/listings';

// MAX FOR TITLE SHOULD BE 34 CHARACTERS

const HomePage = () => {
  const renderListings = () => {
    const renderedListings = listings.map((l, i) => (
      <Link to={`/shop/${l._id}`} key={`listing-card-${l._id}`}>
        <div className="listing-card">
          <div className="images">
            <img
              src={require(`../api/images/${l.images[0].src}`)}
              alt={`rand_img_listing_${l._id}`}
            ></img>
          </div>
          <div className="details">
            <div className="title">{l.title}</div>
            <div className="subtitle">{l.subtitle}</div>
          </div>
        </div>
      </Link>
    ));

    return renderedListings;
  };
  return (
    <div className="page home-page">
      <div className="listings">{renderListings()}</div>
    </div>
  );
};

export default HomePage;
