export const getLocation = async () => {
  if ('geolocation' in navigator) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  } else {
    return null;
  }
};

export const getMonthShortName = monthNo => {
  const date = new Date();
  date.setMonth(monthNo);
  return date.toLocaleString('en-US', { month: 'short' });
};

export const getDayName = (year, month, date) => {
  var date = new Date(year, month, date);
  return date.toString().split(' ')[0];
};
