import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Header = () => {
  const location = useLocation();

  const mobile = useSelector(state => state.shopReducer.mobile);
  const tablet = useSelector(state => state.shopReducer.tablet);

  const prevMobileRef = useRef(false);

  const [clicked, setClicked] = useState(true);
  const [animations, setAnimations] = useState(false);
  const [showIcons, setShowIcons] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);

  useEffect(() => {
    if (window.innerWidth >= 700) {
      startAnimation();
    }

    window.innerWidth < 700
      ? setShowIcons(true)
      : setTimeout(() => {
          setShowIcons(true);
        }, 2000);
  }, []);

  useEffect(() => {
    console.log('CHANGED');
    console.log(prevMobileRef.current);
    console.log(mobile);

    if (prevMobileRef.current === false && mobile === true) {
      setAnimations(false);
    } else if (prevMobileRef.current === true && mobile === false) {
      startAnimation();
    }

    prevMobileRef.current = mobile;
  }, [mobile]);

  const startAnimation = () => {
    setClicked(false);
    setAnimations(true);

    setTimeout(() => {
      setClicked(true);
    }, 100);
  };

  // useEffect(() => {
  //   if (mobile && animations) setAnimations(false);
  //   if (!mobile && !animations) setAnimations(true);
  // }, [mobile, animations]);

  // const handleClick = () => {
  //   setClicked(true);
  // };

  const renderOtherStreets = () => {
    let streets = ['aqua', 'yellow', 'pink', 'orangered'];
    const renderedStreets = streets.map((s, i) => {
      return (
        <div
          key={`other-street-${i}`}
          className={`other-street ${mobile ? 'mobile' : ''} ${
            clicked ? 'clicked' : ''
          } ${animations ? 'animations' : ''}`}
          style={{
            backgroundColor: s,
            // marginLeft: `${-80 + 10 * i}%`
            marginLeft: `calc(-80% + (200px * ${i}))`
            // marginTop: `6%`
            // do something with marginTop for diff screen sizes
          }}
        ></div>
      );
    });
    return renderedStreets;
  };

  const renderIcons = () => {
    return (
      <>
        <Link to="/">
          <i
            className={`header-icon fa-solid fa-shop ${animations ? 'animations' : ''}`}
            style={{
              color:
                location.pathname.split('/')[1] === '' ||
                location.pathname.split('/')[1] === 'shop'
                  ? 'yellow'
                  : 'white',
              opacity: showIcons ? 1 : 0
            }}
          ></i>
        </Link>

        <Link to="/account">
          <i
            className={`header-icon fa-solid fa-user ${animations ? 'animations' : ''}`}
            style={{
              color: location.pathname.split('/')[1] === 'account' ? 'yellow' : 'white',
              opacity: showIcons ? 1 : 0
            }}
          ></i>
        </Link>
        <Link to="/cart">
          <i
            className={`header-icon fa-solid fa-shopping-cart ${
              animations ? 'animations' : ''
            }`}
            style={{
              color: location.pathname.split('/')[1] === 'cart' ? 'yellow' : 'white',
              opacity: showIcons ? 1 : 0
            }}
          ></i>
        </Link>
      </>
    );
  };

  return (
    <>
      {(!mobile && animations) || mobile ? (
        <div
          className={`header-container ${animations ? 'animations' : ''}`}
          // style={{ height: clicked ? (mobile ? `105px` : `60px`) : `100vh` }}
          style={{ height: clicked ? `60px` : `100vh` }}
        >
          <div
            className={`header ${clicked ? 'clicked' : ''} ${
              animations ? 'animations' : ''
            }`}
          >
            <div
              className={`shukr-street ${mobile ? 'mobile' : 'not-mobile'} ${
                clicked ? 'clicked' : ''
              } ${animations ? 'animations' : ''}`}
              // onClick={handleClick}
            >
              <div
                className={`shukr-street-text-container  ${clicked ? 'clicked' : ''} ${
                  tablet ? 'tablet' : mobile ? 'mobile' : ''
                } ${animations ? 'animations' : ''}`}
              >
                <Link to="/">
                  <div
                    className={`shukr-street-text ${clicked ? 'clicked' : ''} ${
                      tablet ? 'tablet' : mobile ? 'mobile' : ''
                    } ${animations ? 'animations' : ''}`}
                  >
                    SHUKR STREET
                  </div>
                </Link>
              </div>
              {clicked ? (
                <div className={`header-icons-container ${mobile ? 'mobile' : ''}`}>
                  {mobile ? (
                    <div className="sandwich-menu">
                      <div
                        className={`sandwich-icon-container ${
                          tablet ? '' : mobile ? 'mobile' : ''
                        } ${mobile || animations ? 'animations' : ''}`}
                        style={{ opacity: showIcons ? 1 : 0 }}
                      >
                        <i
                          className={`header-icon fa-solid fa-bars `}
                          onClick={() => {
                            setMenuOpened(!menuOpened);
                          }}
                        ></i>
                      </div>
                      <div
                        className={`menu-dropdown-container ${
                          menuOpened ? 'opened' : ''
                        } ${mobile || animations ? 'animations' : ''}`}
                        style={{ marginTop: menuOpened ? '0' : '-150px' }}
                      >
                        {renderIcons()}
                      </div>
                    </div>
                  ) : (
                    <div className="open-menu">{renderIcons()}</div>
                  )}
                </div>
              ) : null}
            </div>
            <div
              className={`other-streets-container ${clicked ? 'clicked' : ''} ${
                animations ? 'animations' : ''
              }`}
            >
              {renderOtherStreets()}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`header-container ${animations ? 'animations' : ''}`}
          // style={{ height: clicked ? (mobile ? `105px` : `60px`) : `100vh` }}
          style={{ height: (!mobile && animations) || mobile ? `60px` : `100vh` }}
        ></div>
      )}
    </>
  );
};

export default Header;
