import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { getLocation } from '../utils/functions';
import { getMonthShortName } from '../utils/functions';
import { getDayName } from '../utils/functions';
import { Auth } from 'aws-amplify';

export const onAuthEvent = createAsyncThunk('shopReducer/onAuthEvent', async data => {
  console.log(data);
  return data;
});

export const checkAuth = createAsyncThunk('shopReducer/checkAuth', async () => {
  try {
    const data = await Auth.currentAuthenticatedUser();
    return data;
  } catch (err) {
    return null;
  }
});

const daysInMonth = (year, month) => new Date(year, month, 0).getDate();

export const getDates = createAsyncThunk('shopReducer/getDates', async options => {
  let dates = [];
  let numDays = daysInMonth(options.year, options.month + 1);
  console.log(numDays);
  for (let i = 0; i < numDays; i++) {
    dates.push({
      day: `${getDayName(options.year, options.month, i + 1)}, ${getMonthShortName(
        options.month
      )} ${i + 1}`
    });
  }
  return dates;
});

export const fetchPrayerTimes = createAsyncThunk(
  'shopReducer/fetchPrayerTimes',
  async options => {
    try {
      let url;

      if (options.localeType === 'location') {
        try {
          console.log('hello');
          const position = await getLocation();
          console.log(position);
          const lat = position.coords.latitude;
          const long = position.coords.longitude;
          url = `https://api.aladhan.com/v1/calendar/${options.year}/${
            options.month + 1
          }?school=${options.schoolCode}&method=${
            options.methodCode
          }&latitude=${lat}&longitude=${long}`;
        } catch (err) {
          console.log(err);
          throw Error('You have not given us permission to access your location.');
        }
      } else if (options.localeType === 'city') {
        url = `https://api.aladhan.com/v1/calendarByCity/${options.year}/${
          options.month + 1
        }?school=${options.schoolCode}&method=${options.methodCode}&city=${
          options.city
        }&country=${options.country}`;
      } else throw Error;

      const res = await axios.get(url);
      let data = res.data.data;

      let prayerTimes = data.map((d, i) => {
        for (let p in d.timings) {
          let newStr;
          newStr = d.timings[p].substring(0, d.timings[p].indexOf(' '));
          let hours = newStr.substring(0, newStr.indexOf(':'));
          let mins = newStr.substring(newStr.indexOf(':') + 1);
          let ampm = 'am';
          hours = parseInt(hours, 10);
          if (hours >= 12) {
            ampm = 'pm';
            hours = hours - 12;
          }
          if (hours === 0) {
            hours = 12;
          }

          newStr = `${hours}:${mins}`;
          d.timings[p] = { str: newStr, ampm };
        }
        let timings = {
          Fajr: { str: d.timings.Fajr.str, ampm: d.timings.Fajr.ampm },
          Dhuhr: { str: d.timings.Dhuhr.str, ampm: d.timings.Dhuhr.ampm },
          Asr: { str: d.timings.Asr.str, ampm: d.timings.Asr.ampm },
          Maghrib: { str: d.timings.Maghrib.str, ampm: d.timings.Maghrib.ampm },
          Isha: { str: d.timings.Isha.str, ampm: d.timings.Isha.ampm }
        };
        return {
          day: `${getDayName(options.year, options.month, i + 1)}, ${getMonthShortName(
            options.month
          )} ${i + 1}`,
          timings
        };
      });
      return prayerTimes;
    } catch (err) {
      throw Error(err);
    }
  }
);

const shopSlice = createSlice({
  name: 'shopReducer',
  initialState: {
    prayerTimes: [],
    revertToCity: false,
    dates: [],
    auth: null,
    mobile: false,
    tablet: false
  },
  reducers: {
    undoRevertToCity: (state, action) => {
      state.revertToCity = false;
    },
    setMobile: (state, action) => {
      state.mobile = action.payload;
    },
    setTablet: (state, action) => {
      state.tablet = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(onAuthEvent.fulfilled, (state, action) => {
      state.auth = action.payload;
    });
    builder.addCase(checkAuth.fulfilled, (state, action) => {
      state.auth = action.payload;
    });
    builder.addCase(getDates.fulfilled, (state, action) => {
      state.dates = action.payload;
    });
    builder.addCase(fetchPrayerTimes.fulfilled, (state, action) => {
      state.prayerTimes = action.payload;
    });
    builder.addCase(fetchPrayerTimes.rejected, (state, action) => {
      console.log(action);
      // alert(action.error.message);
      if (
        !state.revertToCity &&
        action.error.message ===
          'You have not given us permission to access your location.'
      )
        state.revertToCity = true;
    });
  }
});

export const { setMobile, setTablet, undoRevertToCity } = shopSlice.actions;

export default shopSlice.reducer;
