// ALL FEATURES
const prayerTimes = 'prayerTimes',
  prayerTimesInDepth = 'prayerTimesInDepth',
  kidsEverythingChart = 'kidsEverythingChart',
  kidsSummarySticker = 'kidsSummarySticker',
  themes = 'themes',
  customColors = 'customColors',
  blankGoals = 'blankGoals',
  miniReflections = 'miniReflections';

// ALL LISTINGS
const listings = [
  {
    _id: `mini-muslim-book-3-pack`,
    title: `My Mini Muslim Book 3-Pack`,
    subtitle: `50-Page Pre-printed Journals With Custom Prayer Timings`,
    link: `mini-muslim-book-3-pack`,
    images: [{ src: `clock_gear.png` }],
    originalPrice: 1.99,
    discountedPrice: null,
    published: Date.now(),
    inventory: 12,
    features: [
      prayerTimes,
      prayerTimesInDepth,
      themes,
      customColors,
      blankGoals,
      miniReflections
    ],
    themes: [
      {
        themeColor: `#f6d8ff`,
        frontCover: { label: 'Front Cover', color: '#f6d8ff' },
        frontCoverText: { label: 'Front Cover Text', color: '#5ce1e6' },
        backCover: { label: 'Back Cover', color: '#5ce1e6' },
        dayHeader: { label: 'Day Header', color: '#5ce1e6' },
        prayers: { label: 'Prayers', color: '#f6d8ff' }, // chart
        quranBoxes: { label: 'Goal Boxes', color: '#5ce1e6' }, // blankGoals
        quranLabel: { label: 'Footer', color: '#e399fa' } // miniReflection
      }
    ],
    private: false
  },
  {
    _id: `mini-mcna-journal`,
    title: `My Mini MCNA Journal`,
    subtitle: `MCNA Elmont`,
    link: `mini-mcna-journal`,
    images: [{ src: `clock_gear.png` }],
    originalPrice: 1.99,
    discountedPrice: null,
    published: Date.now(),
    inventory: 12,
    features: [themes, kidsEverythingChart, kidsSummarySticker],
    themes: [
      {
        themeColor: `#f6d8ff`,
        frontCover: { label: 'Front Cover', color: '#f6d8ff' },
        frontCoverText: { label: 'Front Cover Text', color: '#5ce1e6' },
        backCover: { label: 'Back Cover', color: '#5ce1e6' },
        dayHeader: { label: 'Day Header', color: '#5ce1e6' },
        chart: { label: 'Everything Chart', color: '#f6d8ff' }
      }
    ],
    private: true
  }
];

export default listings;
