import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import $ from 'jquery';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import Day from './Day';
import BlankPage from './BlankPage';
import Opening from './Opening';
import FrontCover from './FrontCover';
import BackCover from './BackCover';
import { getDates } from '../../store/shopSlice';

const MiniMCNAJournal = props => {
  const dispatch = useDispatch();

  const dates = useSelector(state => state.shopReducer.dates);

  const [downloading, setDownloading] = useState(false);
  //   const [displayDoc, setDisplayDoc] = useState(false);

  useEffect(() => {
    dispatch(
      getDates({
        year: 2023,
        month: 6
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (downloading) {
      const HTMLtoPDF = async () => {
        var doc = new jsPDF('p', 'in', [11, 8.5]);

        let pageGroups = $(`.mini-single-journal-page-group`).get();

        for (let i = 0; i < pageGroups.length; i += 2) {
          let page1 = await html2canvas(pageGroups[i], { scale: `8` });
          let dURL = page1.toDataURL('image/jpeg', 1.0);
          doc.addImage(dURL, 'JPEG', 0.5, 0.2, 7.5, 10.5);
          doc.addPage([8.5, 11]);

          let page2 = await html2canvas(pageGroups[i + 1], { scale: `8` });
          let dURL2 = page2.toDataURL('image/jpeg', 1.0);
          doc.addImage(dURL2, 'JPEG', 0.5, 0.2, 7.5, 10.5);

          if (i + 2 < pageGroups.length) doc.addPage([8.5, 11]);
        }

        doc.save('sample.pdf');
      };

      // // await HTMLtoPDF();
      HTMLtoPDF()
        .then(x => {
          console.log('done');
          setDownloading(false);
        })
        .catch(e => {
          console.log(e);
        });
    }
  }, [downloading]);

  async function onDownload() {
    console.log('hello');
    setDownloading(true);
  }

  const renderImages = () => {
    let images = [];

    images.push(
      <FrontCover key={`display-front-cover`} colorSettings={props.colorSettings} />
    );
    images.push(
      <BackCover key={`display-back-cover`} colorSettings={props.colorSettings} />
    );
    images.push(
      <Opening key={`display-opening-page`} colorSettings={props.colorSettings} />
    );
    // images.push(<BlankPage key={`display-blank-page`} />);
    images.push(
      <Day
        day={dates[0].day}
        key={`display-day-page`}
        colorSettings={props.colorSettings}
      />
    );

    return (
      <>
        <div className="images">{images}</div>
      </>
    );
  };

  return (
    <>
      {dates.length > 0 ? (
        <div className="mini-single-journal">
          {renderImages()}
          {/* DELETE ADMIN-BUTTON BEFORE PROD PUSH */}
          {/* <div className="admin-button" onClick={() => setDisplayDoc(!displayDoc)}></div>
          {displayDoc ? renderDoc() : renderImages()} */}
        </div>
      ) : null}
    </>
  );
};

export default MiniMCNAJournal;
