import React from 'react';

const FrontCover = props => {
  const handleClick = e => {
    if (e.target.matches('span')) {
      props.setCurrentColorSetting('frontCoverText');
    } else {
      props.setCurrentColorSetting('frontCover');
    }
  };

  return (
    <div
      className="mini-single-journal-page mini-single-journal-page-front-cover"
      style={{
        backgroundColor: props.colorSettings.frontCover.color,
        textShadow: `5px 5px 0 ${props.colorSettings.frontCoverText.color}`
      }}
      onClick={handleClick}
    >
      <span>MY</span>
      <span>MINI</span>
      <span>MUSLIM</span>
      <span>BOOK</span>
    </div>
  );
};

export default FrontCover;
