import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './styles/index.scss';

import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import PageLayout from './layout/PageLayout';
import ListingPage from './pages/ListingPage';
import AccountPage from './pages/AccountPage';
import CartPage from './pages/CartPage';
import { useDispatch } from 'react-redux';
import { checkAuth, onAuthEvent, setMobile, setTablet } from './store/shopSlice';
import { Hub } from 'aws-amplify';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAuth());

    Hub.listen('auth', data => {
      dispatch(onAuthEvent(data.payload.data));
    });
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    // return () => {
    //   window.removeEventListener('resize', handleResize);
    // };
  }, []);

  const handleResize = () => {
    if (window.innerWidth > 600 && window.innerWidth < 700) {
      dispatch(setTablet(true));
    } else dispatch(setTablet(false));

    if (window.innerWidth > 700) {
      dispatch(setMobile(false));
    } else if (window.innerWidth) {
      dispatch(setMobile(true));
    }
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<PageLayout />}>
            <Route exact path="/" element={<HomePage />}></Route>
            <Route path="/shop" element={<Navigate to="/" />} />
            <Route exact path="/shop/:id" element={<ListingPage />}></Route>
            <Route exact path="/account" element={<AccountPage />}></Route>
            <Route exact path="/cart" element={<CartPage />}></Route>
          </Route>

          <Route exact path="/page-not-found" element={<NotFoundPage />}></Route>
          <Route exact path="/*" element={<NotFoundPage />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
