import React from 'react';
import $ from 'jquery';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

// height: 5.25 inches
// width one page: 3.75 inches
// width altogether: 7.5 inches

const Cover = props => {
  const HTMLtoPDF = async () => {
    var doc = new jsPDF('p', 'in', [11, 8.5]);

    let pageGroups = $(`.mini-single-journal-page-group-cover`).get();

    let page1 = await html2canvas(pageGroups[0], { scale: `8` });
    let dURL = page1.toDataURL('image/jpeg', 1.0);
    doc.addImage(dURL, 'JPEG', 0.5, 0.2, 7.5, 10.5);

    // doc.addPage([8.5, 11]);

    // let page2 = await html2canvas(pageGroups[1], { scale: `8` });
    // let dURL2 = page2.toDataURL('image/jpeg', 1.0);
    // doc.addImage(dURL2, 'JPEG', 0.5, 0, 7.5, 10.5);

    doc.save('cover.pdf');
  };

  // // await HTMLtoPDF();
  const onDownload = async () => {
    HTMLtoPDF()
      .then(x => {
        console.log('done');
      })
      .catch(e => {
        console.log(e);
      });
  };

  const renderFrontCover = () => {
    return (
      <div
        className="mini-single-journal-cover-page front-cover"
        style={{
          backgroundColor: props.primaryColor,
          textShadow: `5px 5px 0 ${props.secondaryColor}`
        }}
      >
        <div onClick={onDownload}>MY</div>
        <div>MINI</div>
        <div>MUSLIM</div>
        <div>BOOK</div>
      </div>
    );
  };

  const renderFrontCoverInside = () => {
    return <div className="mini-single-journal-cover-page front-cover-inside"></div>;
  };

  const renderBackCover = () => {
    return (
      <div
        className="mini-single-journal-cover-page back-cover"
        style={{ backgroundColor: props.secondaryColor }}
      >
        Shukr Street
      </div>
    );
  };

  const renderBackCoverInside = () => {
    return <div className="mini-single-journal-cover-page back-cover-inside"></div>;
  };

  return (
    <div className="cover">
      {props.displayDoc ? (
        <>
          <div className="page-group mini-single-journal-page-group-cover">
            <div className="page-pair">
              {renderBackCover()}
              {renderFrontCover()}
            </div>
            <div className="page-pair">
              {renderBackCover()}
              {renderFrontCover()}
            </div>
          </div>
          <div className="page-group mini-single-journal-page-group-cover">
            <div className="page-pair">
              {renderFrontCoverInside()}
              {renderBackCoverInside()}
            </div>
            <div className="page-pair">
              {renderFrontCoverInside()}
              {renderBackCoverInside()}
            </div>
          </div>
        </>
      ) : (
        <div className="page-group mini-single-journal-page-group-cover">
          <div className="page-pair">
            {renderBackCover()}
            {renderFrontCover()}
          </div>
        </div>
      )}
    </div>
  );
};

export default Cover;
