import React from 'react';

import openingPage from './images/opening-page.png';

const Opening = () => {
  return (
    <div
      className="mini-single-journal-page mini-single-journal-page-opening"
      style={{ backgroundColor: 'white' }}
    >
      <img src={openingPage} alt="opening-page"></img>
    </div>
  );
};

export default Opening;
