import React from 'react';

const BackCover = props => {
  return (
    <div
      className="mini-single-journal-page mini-single-journal-page-back-cover"
      style={{ backgroundColor: props.colorSettings.backCover.color }}
      onClick={() => props.setCurrentColorSetting('backCover')}
    >
      <div>Shukr Street</div>
    </div>
  );
};

export default BackCover;
