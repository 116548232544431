import { configureStore } from '@reduxjs/toolkit';
import reduxLogger from 'redux-logger';

import shopReducer from './shopSlice';

const reducers = {
  shopReducer
};

const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware(
      { serializableCheck: false } // this is because of the error in checkAuth amplify
    )
  // .concat(reduxLogger)
});

export default store;
